import React from "react";
import { Row, Col } from "antd";
import {
  ContainerContent,
  Layout,
  SEO,
  ContainerContentFixed,
  BannerPage,
  ContentLanguage,
  InternalLink,
} from "../components";

import { ContactMap, ContactInfo, ContactForm } from "../components";
import { Colors } from "../components";
import { InternalLinkTypes } from "../components";

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact us"
      description={
        "For all enquiries, questions and feedback, please contact us via the email/phone or the enquiry form"
      }
    />
    <BannerPage
      title={"Contact Us"}
      subTitle={
        "For all enquiries, questions and feedback, please contact us via the email/phone below or via the enquiry form"
      }
    />

    <ContainerContent>
      <ContainerContentFixed>
        <Row gutter={32}>
          <Col md={12}>
            <ContactInfo />
          </Col>
          <Col md={12}>
            <ContactForm />
            <p style={{ padding: "1rem" }}>
              If you are enquiring to get a quote, please fill in our{" "}
              <InternalLink url={InternalLinkTypes.GetStarted}>
                Get Started
              </InternalLink>{" "}
              form.
            </p>
          </Col>
        </Row>
      </ContainerContentFixed>
    </ContainerContent>
    <ContainerContent style={{ backgroundColor: Colors.LightGray }}>
      <div style={{ marginTop: "16px" }}>
        <ContentLanguage />
      </div>
    </ContainerContent>
    <ContactMap />
  </Layout>
);

export default ContactPage;
